import clsx from 'clsx';
import { ComponentProps, forwardRef, useCallback, useEffect } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import loadable from '@loadable/component';
const Slider = loadable(() => import('react-slick'), {
  ssr: false,
});
type TContentSliderProps = ComponentProps<typeof Slider>;

export const ContentSlider = forwardRef<any, TContentSliderProps>((props, ref = null) => {
  const { className } = props;

  const hasScrollOfTrack = useCallback((slider: any) => {
    const trackSlider = slider?.current?.innerSlider?.track?.props;

    if (!trackSlider) {
      return true;
    }

    return trackSlider.slideCount > trackSlider.slidesToScroll;
  }, []);

  return (
    <Slider
      {...props}
      className={clsx(className, 'content-slider', {
        'slider-no-scroll': !hasScrollOfTrack(ref),
      })}
      ref={ref}
    />
  );
});
